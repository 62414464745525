<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.80588 0.830005C8.85628 -0.276668 7.14372 -0.276668 6.19412 0.830005C5.70061 1.40515 4.96265 1.71082 4.207 1.6531C2.75299 1.54203 1.54203 2.75299 1.6531 4.207C1.71082 4.96265 1.40515 5.70061 0.830005 6.19412C-0.276668 7.14372 -0.276668 8.85628 0.830005 9.80588C1.40515 10.2994 1.71082 11.0373 1.6531 11.793C1.54203 13.247 2.75299 14.458 4.207 14.3469C4.96265 14.2892 5.70061 14.5949 6.19412 15.17C7.14372 16.2767 8.85628 16.2767 9.80588 15.17C10.2994 14.5949 11.0373 14.2892 11.793 14.3469C13.247 14.458 14.458 13.247 14.3469 11.793C14.2892 11.0373 14.5949 10.2994 15.17 9.80588C16.2767 8.85628 16.2767 7.14372 15.17 6.19412C14.5949 5.70061 14.2892 4.96265 14.3469 4.207C14.458 2.75299 13.247 1.54203 11.793 1.6531C11.0373 1.71082 10.2994 1.40515 9.80588 0.830005ZM8.61346 4.53047C8.35218 4.03527 7.64297 4.03527 7.38169 4.53047L6.73997 5.74676C6.63921 5.93773 6.45557 6.07116 6.24281 6.10797L4.88775 6.34243C4.33604 6.43789 4.11688 7.1124 4.50711 7.51391L5.46557 8.50008C5.61605 8.65492 5.6862 8.8708 5.65547 9.08453L5.45971 10.4457C5.38001 10.9999 5.95378 11.4168 6.45623 11.1697L7.69031 10.5629C7.88408 10.4677 8.11107 10.4677 8.30484 10.5629L9.53892 11.1697C10.0414 11.4168 10.6151 10.9999 10.5354 10.4457L10.3397 9.08453C10.3089 8.8708 10.3791 8.65492 10.5296 8.50008L11.488 7.51391C11.8783 7.1124 11.6591 6.43789 11.1074 6.34243L9.75234 6.10797C9.53958 6.07116 9.35594 5.93773 9.25518 5.74676L8.61346 4.53047Z"
      fill="white"
    />
  </svg>
</template>
