<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6445 4.1121C13.5168 2.79792 11.4832 2.79792 10.3555 4.1121C9.76947 4.79508 8.89315 5.15806 7.99581 5.08952C6.26918 4.95763 4.83116 6.39564 4.96305 8.12227C5.0316 9.01961 4.66861 9.89594 3.98563 10.482C2.67146 11.6096 2.67146 13.6433 3.98563 14.7709C4.66861 15.357 5.0316 16.2333 4.96305 17.1307C4.83116 18.8573 6.26918 20.2953 7.99581 20.1634C8.89315 20.0949 9.76947 20.4579 10.3555 21.1408C11.4832 22.455 13.5168 22.455 14.6445 21.1408C15.2305 20.4579 16.1069 20.0949 17.0042 20.1634C18.7308 20.2953 20.1688 18.8573 20.0369 17.1307C19.9684 16.2333 20.3314 15.357 21.0144 14.7709C22.3285 13.6433 22.3285 11.6096 21.0144 10.482C20.3314 9.89594 19.9684 9.01961 20.0369 8.12228C20.1688 6.39564 18.7308 4.95763 17.0042 5.08952C16.1069 5.15806 15.2305 4.79508 14.6445 4.1121ZM13.1137 8.28734C12.8524 7.79214 12.1432 7.79214 11.8819 8.28734L10.9744 10.0073C10.8737 10.1983 10.69 10.3317 10.4772 10.3685L8.56101 10.7001C8.0093 10.7956 7.79014 11.4701 8.18037 11.8716L9.53576 13.2662C9.68624 13.421 9.75639 13.6369 9.72566 13.8506L9.44883 15.7755C9.36913 16.3297 9.9429 16.7466 10.4454 16.4995L12.1905 15.6414C12.3843 15.5462 12.6113 15.5462 12.805 15.6414L14.5502 16.4995C15.0526 16.7466 15.6264 16.3297 15.5467 15.7755L15.2699 13.8506C15.2392 13.6369 15.3093 13.421 15.4598 13.2662L16.8152 11.8716C17.2054 11.4701 16.9862 10.7956 16.4345 10.7001L14.5183 10.3685C14.3055 10.3317 14.1219 10.1983 14.0211 10.0073L13.1137 8.28734Z"
      fill="#8028F8"
    />
  </svg>
</template>
